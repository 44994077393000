import { ReactNode } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './carousel.css'

interface CarouselProps {
	className?: string,
	pagination?: boolean,
	navigation?: boolean,
	children: ReactNode
}

const Carousel = ({ className, pagination = false, navigation = true, children }: CarouselProps) => {
	return (
		<div className={`twd-carousel ${className}`}>
			<Swiper
				// NOTE: className="swiper-horizontal" old version class name to allow scrolling on Android
				modules={[Navigation, Pagination]}
				slidesPerView="auto"
				navigation={navigation}
				pagination={pagination ? { clickable: true } : false}
			>
				{children}
			</Swiper>
		</div>
	)
}

export { Carousel, SwiperSlide }
