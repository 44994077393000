interface PageTitleProps {
	title: string,
	src?: string,
	className?: string
}

const PageTitle = ({ title, src, className } : PageTitleProps) => {
	return (
		<div className={`flex ${className} mb-4 mt-2`}>
			{/*<img src={src} className="my-auto mr-2 h-12 md:h-14" alt={title} />*/}
			<h1 className="my-auto text-2xl font-medium md:text-3xl">{title}</h1>
		</div>
	)
}

export default PageTitle
