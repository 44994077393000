// NOTE: hooks
import React, { lazy, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
// NOTE: components
import Loading from './components/ui/TWDLoading'
// NOTE: routes
import managerRoutes from './pages/manager/Index'
import adminRoutes from './pages/admin/Index'

const Errors = lazy(() => import('./pages/404'))
const Auth = lazy(() => import('./features/auth/Auth'))

const LoadPage = ({ page }: any) => {
	return <Suspense fallback={<Loading />}>{page}</Suspense>
}

const App = () => {
	const routing = useRoutes([
		...managerRoutes,
		...adminRoutes,
		{
			path: 'auth',
			element: <LoadPage page={<Auth />} />
		},
		{
			path: '*',
			element: <LoadPage page={<Errors />} />
		}
	])

	return routing
}

export default App
