// TODO: clean this component and break down in a folder

// NOTE: hooks
import { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { useAuthContext } from '../../hooks/useAuthContext'
// NOTE: components
import Module from './Card'
import Modal from './Modal'
import Tooltip from '@mui/material/Tooltip'
// NOTE: images
import logoutIcon from '../../assets/img/twd/logout.png'
import settingsIcon from '../../assets/img/twd/settings.png'
import setupIcon from '../../assets/img/twd/submit.png'
import avatarPlaceholderImage from '../../assets/img/placeholder.png'

interface NavBarProps {
	links: any,
	home: any,
	icon: string,
	dashboard: any
}

const NavBar = ({ links, home, icon, dashboard } : NavBarProps) => {
	const { user } = useAuthContext()
	const { logout } = useAuth()
	const [show, setShow] = useState(false)

	return (
		<>
			<Modal show={show} onHide={() => setShow(false)} size="sm">
				<Modal.Header onHide={() => setShow(false)}>Hi, {user?.fname}!</Modal.Header>

				<div className="border-b-2 border-[#f6f8f9] p-4">
					<NavLink
						className="flex-fill flex w-full flex-col"
						to="/dashboard/account"
						onClick={() => setShow(false)}
					>
						<img
							src={user.profilePicture ?? avatarPlaceholderImage}
							alt="Profile"
							className="mx-auto h-28 rounded-full border-2 border-[#66CCCC] p-1 shadow-inner"
							loading="eager"
						/>
						<p className="font-regular mt-2 text-center text-lg">{user?.email}</p>
					</NavLink>
				</div>

				<div className="p-4">
					<NavLink
						className="my-2 flex px-0 md:px-4"
						to="/dashboard/account/setup"
						onClick={() => setShow(false)}
					>
						<img src={setupIcon} className="mr-2 h-14" alt="Setup" />
						<p className="my-auto pb-1 text-xl">Complete your account</p>
					</NavLink>
					<NavLink
						className="my-2 flex px-0 md:px-4"
						to="/dashboard/account"
						onClick={() => setShow(false)}
					>
						<img src={settingsIcon} className="mr-2 h-14" alt="Settings" />
						<p className="my-auto pb-1 text-xl">Settings</p>
					</NavLink>

					<button className="my-2 flex w-full px-0 md:px-4" onClick={logout}>
						<img src={logoutIcon} className="mr-2 h-14" alt="Logout" />
						<p className="my-auto pb-1 text-xl">Log out</p>
					</button>
				</div>
			</Modal>

			<nav>
				<PhoneNav
					links={links}
					// home={home}
					// handleLogOut={logout}
					profilePicture={user.profilePicture ?? avatarPlaceholderImage}
					// icon={icon}
					openAccount={() => setShow(true)}
					className="block md:hidden"
				/>

				<DeskNav
					links={links}
					home={home}
					// handleLogOut={logout}
					profilePicture={user.profilePicture ?? avatarPlaceholderImage}
					icon={icon}
					openAccount={() => setShow(true)}
					className="hidden md:block"
					dashboard={dashboard}
				/>
			</nav>
		</>
	)
}

interface PhoneNavProps {
	links: any,
	profilePicture: string,
	openAccount: any,
	className: string
}

const PhoneNav = ({ links, profilePicture, openAccount, className } : PhoneNavProps) => {
	return (
		<Module
			className={`${className} no-scrollbar fixed bottom-0 z-20`}
			style={{
				backgroundColor: 'rgba(255, 255, 255, 0.9)',
				boxShadow: 'rgba(149, 157, 165, 0.2) 0px 0px 24px',
				overflowX: 'scroll',
				border: 'none',
				borderRadius: '0',
				width: '100%'
			}}
		>
			<div className="flex justify-around p-2">
				{links.map((link: any) => (
					<div key={link.to}>
						<NavLink
							className={({ isActive }) =>
								`${isActive ? 'active' : null} nav-link flex h-full flex-col p-3`
							}
							to={link.to}
							end={link.text?.toLowerCase() === 'home'}
						>
							{({ isActive }) => (
								<Tooltip title={link.text}>
									<img
										src={link.icon}
										alt="Link icon"
										className={`m-auto max-h-[34px] max-w-[34px] ${isActive ? 'opacity-100 saturate-100' : 'opacity-80 saturate-50'}`}
										loading="eager"
									/>
								</Tooltip>
							)}
						</NavLink>
					</div>
				))}
				<button onClick={openAccount}>
					{profilePicture && (
						<Tooltip title="Account">
							<img
								src={profilePicture}
								style={{
									width: 40,
									height: 40,
									maxWidth: 40,
									maxHeight: 40,
									borderRadius: '50%',
									padding: 2,
									border: '1.5px solid #66CCCC'
								}}
								className="shadow-inner"
								alt="Profile"
								loading="eager"
							/>
						</Tooltip>
					)}

					{!profilePicture && (
						<div
							className="skeleton"
							style={{
								width: 40,
								height: 40,
								maxWidth: 40,
								maxHeight: 40,
								borderRadius: '50%',
								padding: 2,
								border: '1.5px solid #66CCCC'
							}}
						/>
					)}
				</button>
			</div>
		</Module>
	)
}

interface DeskNavProps {
	links: any,
	home: any,
	icon: string,
	profilePicture: string,
	openAccount: any,
	className: string,
	dashboard: any
}

const DeskNav = ({ links, home, icon, profilePicture, openAccount, className, dashboard } : DeskNavProps) => {
	return (
		<div
			style={{
				height: '100vh',
				flexShrink: 0,
				backgroundColor: '#f1f1f1',
				position: 'sticky',
				bottom: 0,
				top: 0
			}}
			className={`${className} w-[110px] lg:w-[260px]`}
		>
			<div
				style={{
					backgroundColor: 'white',
					border: '2px solid rgba(51, 66, 81, 0.1)',
					width: '100%',
					height: '100%'
				}}
			>
				<div className="flex flex-col p-2" style={{ height: '100%', overflowY: 'scroll' }}>
					<Link to={home} className="mx-auto mb-8 mt-6">
						<img
							src={icon}
							alt="The Wellbeing Doctors"
							className="h-[65px] lg:h-[120px]"
							loading="eager"
						/>
					</Link>

					{links.map((link: any) => (
						<div key={link.to}>
							<NavLink
								className={({ isActive }) =>
									`${isActive ? 'active' : null} nav-link mt-4 flex px-4 py-2`
								}
								to={link.to}
								end={link.text?.toLowerCase() === 'home'}
							>
								{({ isActive }) => (
									<>
										<img
											src={link.icon}
											className={`mx-auto h-auto w-[42px] lg:mx-0 lg:w-[30px] ${isActive ? 'opacity-100 saturate-100' : 'opacity-80 saturate-50'}`}
											alt={link.text}
											loading="eager"
										/>
										<span className="my-auto hidden pl-4 lg:inline">{link.text}</span>
									</>
								)}
							</NavLink>
						</div>
					))}

					<div className="mt-auto flex flex-col">
						<button className="nav-link mb-8 mt-4 flex px-4 py-2" onClick={openAccount}>
							{profilePicture && (
								<img
									src={profilePicture}
									style={{
										borderRadius: '50%',
										padding: 2,
										border: '1.5px solid #66CCCC'
									}}
									className="mx-auto size-[55px] shadow-inner lg:mx-0 lg:size-[40px]"
									alt="Profile"
									loading="eager"
								/>
							)}
							{!profilePicture && (
								<div
									style={{
										borderRadius: '50%',
										padding: 2,
										border: '1.5px solid #66CCCC'
									}}
									className="skeleton mx-auto size-[55px] lg:mx-0 lg:size-[40px]"
								/>
							)}
							<span className="my-auto hidden pl-4 lg:inline">Account</span>
						</button>

						{dashboard === 'staff' && (
							<>
								<hr />

								<div className="my-2 px-3 py-2">
									<p className="text-xs font-bold">helfy® by The Wellbeing Doctors®</p>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default NavBar
