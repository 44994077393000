import { useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useAuth } from '../../hooks/useAuth'
import useRedirectToAuth from '../../hooks/useRedirectToAuth'
import useGetManagerTeam from '../../hooks/api/useGetManagerTeam'

import { toast } from 'sonner'

const useManagerAuthorisation = () => {
	const { dispatch, user } = useAuthContext()
	const { logout } = useAuth()
	const redirectToAuth = useRedirectToAuth()
	const { getManagerTeam } = useGetManagerTeam()
	const [loading, setLoading] = useState(true)

	const getManagerData = async (isAuthorisationNeeded: boolean) => {
		try {
			// NOTE: get the saved user object from local storage from previous logins
			const userLocalStorage = localStorage.getItem('user')
			const savedUser = userLocalStorage && JSON.parse(userLocalStorage)

			if (!savedUser) return redirectToAuth()

			// NOTE: when user use authenticated with the old token structure - this won't be needed with auth.js
			if (savedUser.displayName || savedUser.position) return logout()

			// NOTE: get manger data and update context
			const team = await getManagerTeam(user.email, user.token)
			dispatch({ type: 'UPDATE_COMPANY', payload: team })

			// NOTE: if user is not managing a team send them to the process to create a team on pages that require team data
			//if (isAuthorisationNeeded && !team) navigate('/manager/team/setup', { replace: true })
		} catch (error: any) {
			toast.error("Couldn't load your company:", { description: error.message })
		} finally {
			setLoading(false)
		}
	}

	return {
		loading,
		getManagerData
	}
}

export default useManagerAuthorisation
