import { useAuthContext } from '../useAuthContext'

const useGetManagerTeam = () => {
	const { user, dispatch } = useAuthContext()

	const getManagerTeam = async (email: string, token: string) => {
		const response = await fetch(`/api/health/api/teams/manager/${email}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		})

		if (response.status === 204) {
			// NOTE: update the context if the team has changed, e.g.: another manager changing the logo
			if (user.team) dispatch({ type: 'UPDATE_COMPANY', payload: '' })

			return
		}

		const json = await response.json()

		if (!response.ok) throw Error(json.error)

		if (!json || json.length === 0) {
			// NOTE: update the context if the team has changed, e.g.: another manager changing the logo
			if (user.team) dispatch({ type: 'UPDATE_COMPANY', payload: '' })

			return
		}

		const teamObj = json[0]

		// NOTE: update the context if the team has changed, e.g.: another manager changing the logo
		if (teamObj.updatedAt !== user.team?.updatedAt)
			dispatch({ type: 'UPDATE_COMPANY', payload: teamObj })

		return teamObj
	}

	return { getManagerTeam }
}

export default useGetManagerTeam
