// TODO: hooks
import { Outlet } from 'react-router-dom'
// NOTE: components
import NavBar from './NavBar'
import ManagerNavBar from './ManagerNavBar'

interface LayoutProps {
	links: any
	home: string
	icon?: any
	dashboard: string
}

const Layout = ({ links, home, icon, dashboard }: LayoutProps) => {
	return (
		<div className="flex min-h-screen min-h-svh">
			{dashboard === 'manager' ? (
				<ManagerNavBar links={links} home={home} />
			) : (
				<NavBar links={links} home={home} icon={icon} dashboard={dashboard} />
			)}
			<Outlet />
		</div>
	)
}

export default Layout
