import { useNavigate } from 'react-router-dom'

const useRedirectToAuth = () => {
	const navigate = useNavigate()

	// NOTE: send the user to authenticate with a redirect link to send them to after successfully authenticating
	const redirectToAuth = () => {
		// NOTE: redirect login page with the previous url search parameters and the redirect parameter so auth knows where to redirect the user after login
		const prevSearch = window.location.search
		const search = `${prevSearch ? `${prevSearch}&` : '?'}redirect=${window.location.pathname}`

		navigate(`/auth${search}`)
	}

	return redirectToAuth
}

export default useRedirectToAuth
